module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.hughesnetdeals.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-KBLNS7M9","brandTokens":["HN","TND"],"mapiBrandToken":"HN","siteName":"hughesnetdeals","alternateName":"hughesnetdeals.com","siteURL":"https://www.hughesnetdeals.com","defaultTitleTemplate":"","defaultPhone":"8447150783","phoneSymbol":"-","defaultPromoCode":"169561","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046137","vwoProjectID":"894940","cobra":{"sitePath":"hughesnetdeals.com","buttonLink":"https://www.hughesnet.com/?campaignid=289160&phone_number=877-891-6641&utm_campaign=clearlinkutahseo&utm_medium=referral&utm_source=partner&partnerid=289160&programcode=289160KEPCJ3&request_id={REQUEST_ID}&kbid={PROMO_CODE}","buttonText":"Order Online","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hughesnetdeals","short_name":"hughesnetdeals","start_url":"/","background_color":"#0096FA","theme_color":"#0096FA","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c95954c72d1716af2a00acd1b27bebc6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
