import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const HeroStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-hero.background-color-Dark .leshen-price-change span,
      .leshen-hero.background-color-Secondary .leshen-price-change span {
        color: ${theme.colors.white.base.hex} !important;
      }
    `}
  />
))

export default HeroStyles
