import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const ItemBankStyles = withTheme(() => (
  <Global
    styles={css`
      .city-link-bank {
        div {
          @media (max-width: 768px) {
            justify-content: left;
            gap: 10px;

            a {
              width: unset;
            }
          }
        }
      }
    `}
  />
))

export default ItemBankStyles
