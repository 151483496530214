import React from 'react'

import { ThemeProvider } from '@leshen/ui'
import 'typeface-source-sans-pro'
import siteTheme from '../../theme'
import GlobalStyles from './CustomStyles/GlobalStyles'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

Wrapper.defaultProps = {
  theme: {},
}

export default Wrapper
