import { withTheme } from '@emotion/react'
import React from 'react'
import HelperStyles from './Helper.styles'
import HeroStyles from './Hero.styles'
import ItemBankStyles from './ItemBank.styles'

const GlobalStyles = withTheme(() => (
  <>
    <HeroStyles />
    <ItemBankStyles />
    <HelperStyles />
  </>
))

export default GlobalStyles
